import React from 'react';

const Complementos = (props) => {
  return (
    <svg 
      width={props.width || '32'} 
      height={props.height || '32'} 
      viewBox="0 0 32 32" 
      fill={props.fill || "white"}
      stroke={props.stroke || '#94897F'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4114_1558)">
        <path d="M16 6.09998L13.849 12.6396C13.7389 12.9743 13.5518 13.2785 13.3026 13.5276C13.0535 13.7767 12.7493 13.9639 12.4146 14.074L5.875 16.225L12.4146 18.376C12.7493 18.4861 13.0535 18.6732 13.3026 18.9223C13.5518 19.1715 13.7389 19.4757 13.849 19.8104L16 26.35L18.151 19.8104C18.2611 19.4757 18.4482 19.1715 18.6974 18.9223C18.9465 18.6732 19.2507 18.4861 19.5854 18.376L26.125 16.225L19.5854 14.074C19.2507 13.9639 18.9465 13.7767 18.6974 13.5276C18.4482 13.2785 18.2611 12.9743 18.151 12.6396L16 6.09998Z" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.125 5.875V10.375"  strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23.875 21.625V26.125"  strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.875 8.125H10.375"  strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M21.625 23.875H26.125" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4114_1558">
          <rect width="32" height="32"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Complementos;
