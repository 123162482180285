import Baldosa from './Icons/Baldosa';
import Complementos from './Icons/Complementos';
import Coccion from './Icons/Coccion';
import Ducha from './Icons/Ducha';
import Griferia from './Icons/Griferia';
import Sanitario from './Icons/Sanitario';
import Chevron from './Icons/Chevron';
import React, { useState } from 'react';

const CATEGORY_ICONS = (category, isHovered, isActive) => {
    const activeProps = {
        fill: '#94897F',
        stroke: '#fff',
    };
    const inactiveProps = {
        fill: '#fff',
        stroke: '#94897F',
    };

    switch (category) {
        case 'Baldosa':
            return (
                <Baldosa
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Complementos':
            return (
                <Complementos
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Cocción':
            return (
                <Coccion
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Ducha':
            return (
                <Ducha
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Griferia':
            return (
                <Griferia
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Sanitario':
            return (
                <Sanitario
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        case 'Chevron':
            return (
                <Chevron
                    fill={!isActive || isHovered ? inactiveProps.fill : activeProps.fill}
                    stroke={!isActive || isHovered ? inactiveProps.stroke : activeProps.stroke}
                />
            );
        default:
            return null;
    }
};

export const CategoryButton = ({ category, handleCategoryClick, isActive = false }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onClick={() => handleCategoryClick(category.label)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={`font-montserrat flex justify-between items-center text-base font-medium leading-5 py-[11px] pl-3 pr-[10px] border border-neutrals-400 bg-des-beige-500   text-neutrals-black hover:bg-des-beige-100 ${
                !isActive ? 'bg-transparent' : 'hover:text-neutrals-black text-white'
            }`}
        >
            <div className={`flex items-center flex-col md:flex-row`}>
                {CATEGORY_ICONS(category.label, isHovered, isActive)}
                {category.label}
            </div>
            {CATEGORY_ICONS('Chevron', isHovered, isActive)}
        </button>
    );
};
