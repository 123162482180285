import React, { useState, useEffect } from 'react';
import { Loader } from './Loader';

export function ProductCard({ product, view }) {
    const [isLoading, setIsLoading] = useState(true);

    const {
        permalink,
        name,
        product_type,
        category,
        wishlistShortcode,
        html_price,
        product_discount,
        woosb_discount,
        tag,
        featured_image,
        imageA,
    } = product;

    let imageASrc = '';
    if (imageA) {
        let srcMatches = imageA.match(/src="([^"]+)"/);
        if (srcMatches && srcMatches[1]) {
            imageASrc = srcMatches[1];
        }
    }

    useEffect(() => {
        setIsLoading(false);
    }, [product]);

    const isTabla =
        typeof category === 'string'
            ? category.toLowerCase() === 'tabla'
            : Array.isArray(category)
            ? category.map((cat) => cat.toLowerCase()).includes('tabla')
            : false;

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <a href={permalink} className="relative block">
                        {tag[0]?.name && (
                            <span className="bg-neutrals-100 px-2.5 py-2 mb-2 whitespace-nowrap absolute text-[#333] z-30 border-r border-b border-neutrals-100">
                                {tag[0].name}
                            </span>
                        )}
                        <div className="aspect-w-1 aspect-h-1 relative w-full group border">
                            <img
                                src={featured_image}
                                className="border-neutrals-100 bg-light-gray bg-center bg-no-repeat bg-cover w-full h-full object-container"
                                alt={name}
                            />
                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center ">
                                {imageASrc && (
                                    <img
                                        src={imageASrc}
                                        className="opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-500 w-full h-full object-cover object-center"
                                        alt={name}
                                    />
                                )}
                            </div>
                        </div>
                    </a>
                    <div className="flex justify-between mt-1 items-center">
                        <p className="text-des-beige-500 font-montserrat text-xs font-medium leading-5 uppercase">
                            {product_type}
                        </p>
                        {/* <span dangerouslySetInnerHTML={{ __html: wishlistShortcode }}></span> */}
                    </div>
                    <h3 className="text-neutrals-black font-montserrat text-base font-semibold leading-6">
                        <a href={permalink}>{name}</a>
                    </h3>
                    {!isTabla ? (
                        <div className="flex">
                            <p
                                className="text-neutrals-black font-avenir text-base font-normal leading-relaxed mr-2"
                                dangerouslySetInnerHTML={{ __html: html_price }}
                            />
                            {product_discount > 0 ? (
                                <div className="bg-neutrals-black flex flex-col justify-center items-center px-3">
                                    <p
                                        className="text-white text-sm font-semibold font-montserrat"
                                        style={{ lineHeight: 0 }}
                                    >
                                        {product_discount}%
                                    </p>
                                </div>
                            ) : (
                                woosb_discount &&
                                woosb_discount !== '0' && (
                                    <div className="bg-neutrals-black flex flex-col justify-center items-center px-3">
                                        <p
                                            className="text-white text-sm font-semibold font-montserrat"
                                            style={{ lineHeight: 0 }}
                                        >
                                            {woosb_discount}%
                                        </p>
                                    </div>
                                )
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    );
}
