export const getMinMaxPrices = (products) => {
    let maxPrice = 0;
    let minPrice = 0;
    if (products.length > 0) {
        const prices = products
            .map((product) => Number(product.price))
            .filter((price) => typeof price === 'number' && !isNaN(price));

        maxPrice = Math.max(...prices);
        minPrice = Math.min(...prices);
    }

    return { minPrice, maxPrice };
};
