import React, { useState, useEffect } from 'react';
import { Range } from 'react-range';
import { useProducts } from '../ProductContext';

export const PriceSlider = () => {
    const { state, dispatch } = useProducts();

    const maxPrice = state.allProducts.length
        ? Math.max(...state.allProducts.map((product) => product.price))
        : 4181485;

    const [values, setValues] = useState([state.minPrice, state.maxPrice]);

    useEffect(() => {
        const maxValue = Math.max(...state.allProducts.map((product) => product.price));
        if (maxValue === maxPrice) {
            return;
        }
        setValues([state.minPrice, state.maxPrice]);
    }, [state.filteredProducts]);

    const handleSliderChange = (newValues) => {
        setValues(newValues);
    };

    const handleFinalChange = (newValues) => {
        dispatch({
            type: 'SET_PRICE_FILTER',
            payload: { min: newValues[0], max: newValues[1] },
        });
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('es-AR', {
            style: 'currency',
            currency: 'ARS',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    return (
        <div className="mt-5 p-4">
            <Range
                step={100}
                min={0}
                max={maxPrice}
                values={values}
                onChange={handleSliderChange}
                onFinalChange={handleFinalChange}
                renderTrack={({ props, children }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '3px',
                            width: '100%',
                            backgroundColor: '#94897F',
                            borderRadius: '4px',
                        }}
                    >
                        {children}
                    </div>
                )}
                renderThumb={({ props }) => (
                    <div
                        {...props}
                        style={{
                            ...props.style,
                            height: '16px',
                            width: '16px',
                            backgroundColor: '#BEB5AA',
                            border: '1px solid #BEB5AA',
                            borderRadius: '50%',
                            boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                        }}
                    />
                )}
            />
            <div className="flex justify-center items-center mt-4">
                <span className="text-right text-sm font-montserrat not-italic font-medium leading-7 text-gray-800">
                    {formatCurrency(values[0])}
                </span>
                <span className="mx-2">—</span>
                <span className="text-right text-sm font-montserrat not-italic font-medium leading-7 text-gray-800">
                    {formatCurrency(values[1])}
                </span>
            </div>
        </div>
    );
};
