export const filterProducts = (products, filters) => {
    let filteredProducts = [...products];

    if (filters.category && filters.category.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
            filters.category.some((category) => product?.category.includes(category))
        );
    }

    if (filters.product_type && filters.product_type.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
            filters.product_type.includes(product?.product_type)
        );
    }

    if (filters.serie && filters.serie.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
            filters.serie.includes(product.serie[0]?.name)
        );
    }

    if (filters.tono && filters.tono.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
            filters.tono.includes(product.tono[0]?.name)
        );
    }

    if (filters.medida && filters.medida.length > 0) {
        filteredProducts = filteredProducts.filter((product) =>
            filters.medida.includes(product.medida[0]?.name)
        );
    }

    return filteredProducts;
};
