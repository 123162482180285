import React from 'react';

const Coccion = (props) => {
  return (
    <svg 
      width={props.width || '32'} 
      height={props.height || '32'} 
      viewBox="0 0 32 32" 
      fill= {props.fill || '#fff'}
      stroke= {props.stroke || '#94897F'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
        <path d="M14.8851 5.32824C14.7806 5.26585 14.6613 5.23291 14.5396 5.23291C14.418 5.23291 14.2986 5.26585 14.1941 5.32824C14.108 5.39649 14.0499 5.49397 14.0308 5.60221C14.0117 5.71045 14.0329 5.82193 14.0905 5.91555C16.2152 9.59489 16.6643 14.6043 13.7623 17.3681C12.6457 16.4299 11.7593 15.248 11.1712 13.9134C10.098 14.5094 9.20863 15.388 8.59958 16.4539C7.99053 17.5197 7.68509 18.732 7.71642 19.9592C7.76023 21.0411 8.01999 22.1032 8.4804 23.0831C8.94081 24.0631 9.59258 24.941 10.3974 25.6653C11.2021 26.3896 12.1437 26.9456 13.1665 27.3006C14.1894 27.6556 15.2729 27.8025 16.3534 27.7325C21.9156 27.7325 24.8003 24.2777 24.9903 19.9592C25.2149 14.7771 21.5355 8.40299 14.8851 5.32824Z" strokeWidth="0.965998" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.6718 19.9595C20.6718 20.8757 20.3078 21.7545 19.6599 22.4024C19.012 23.0503 18.1333 23.4143 17.217 23.4143"  strokeWidth="0.965998" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Coccion;
