export const updateUrlWithFilters = (filters) => {
    if (Object.keys(filters).length === 0) {
        return;
    }

    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
        if (value && value.length > 0) {
            params.set(key, value.join(','));
        }
    });

    const newUrl = `${location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    if (newUrl !== location.pathname + location.search) {
        return newUrl;
    }
    return null;
};
