import React, { useState, useEffect } from 'react';
import { useProducts } from '../ProductContext';
import { ProductCard } from './ProductCard';

export function ProductContainer({ products }) {
    const { state } = useProducts();
    const PRODUCTS_PER_PAGE = 24;
    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(products.length / PRODUCTS_PER_PAGE);
    const currentProducts = getCurrentProducts();

    useEffect(() => {
        setCurrentPage(1);
    }, [products]);

    function getCurrentProducts() {
        const indexOfLastProduct = currentPage * PRODUCTS_PER_PAGE;
        const indexOfFirstProduct = indexOfLastProduct - PRODUCTS_PER_PAGE;
        return products.slice(indexOfFirstProduct, indexOfLastProduct);
    }

    function handlePageChange(pageNumber) {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function renderPaginationButton(pageNumber) {
        const isActive = pageNumber === currentPage;
        return (
            <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={`
          px-3 py-2 
          border border-[#827B75] 
          text-lg font-semibold 
          rounded-none
          ${isActive ? 'bg-[#827B75] text-white' : 'bg-white text-[#827B75]'}
          hover:bg-[#E9E8E6] 
          hover:border-[#E9E8E6]
        `}
            >
                {pageNumber}
            </button>
        );
    }

    return (
        <div>
            {products.length === 0 ? (
                <div className="flex justify-center">
                    <p className="mt-4">No hay productos disponibles.</p>
                </div>
            ) : (
                <>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        {currentProducts?.map((product) => (
                            <ProductCard key={product.id} product={product} view={state.view} />
                        ))}
                    </div>

                    <div className="flex justify-center space-x-2 my-9">
                        {[...Array(totalPages)].map((_, index) =>
                            renderPaginationButton(index + 1)
                        )}
                    </div>
                </>
            )}
        </div>
    );
}
