import React, { useState } from 'react';
import { PriceSlider } from './PriceSlider';

export function FilterAccordion({
    title,
    filterType = null,
    filterOptions = null,
    handleCheckboxChange,
    handleSort,
    className = null,
    isOpen = false,
    handleAccordionClick,
    selectedFilters = null,
}) {
    const handleChange = (option) => {
        handleCheckboxChange(filterType, option);
    };

    const handleSorting = (type) => {
        handleSort(type);
    };

    if (
        filterType !== 'sort' &&
        filterType !== 'price' &&
        (!filterOptions || filterOptions.length === 0)
    ) {
        return null;
    }

    // Temporaly hiding the accordion if the filter type is 'price'
    if (filterType === 'price') return null;

    return (
        <div
            data-component="filter-accordion"
            className={`block px-5 filter-title tab ${className} relative z-40 lg:px-0 lg:inline-block h-full border-b-2 border-neutrals lg:border-0`}
        >
            <label
                className="w-full border-none flex justify-between items-center h-20 flex-shrink-0 text-neutrals-black font-montserrat text-[16px] font-semibold leading-[22px] small-caps z-20 border-b-2 border-neutrals lg:border-0"
                htmlFor={`chck-${title}`}
                onClick={handleAccordionClick}
            >
                {title}
                <img
                    className={`w-8 h-8 transform transition-transform duration-300 ease-in-out ${
                        isOpen ? 'rotate-[270deg]' : 'rotate-[90deg]'
                    }`}
                    src="https://cdn.destefano1913.com/wp-content/uploads/ic_tienda_flecha.svg"
                    alt="chevron"
                />
            </label>
            {isOpen && (
                <div
                    className={`w-full lg:w-[300px] p-0 pb-4 lg:p-4 tab-content bg-white transition-max-height overflow-hidden  lg:absolute lg:top-14 lg:left-0 lg:z-40 lg:border lg:border-gray-300 border-t-0`}
                >
                    {filterType === 'sort' ? (
                        <>
                            <div className="flex pt-4 pb-3 items-center space-x-2 self-stretch">
                                <span
                                    className="bg-transparent text-neutrals-black font-montserrat text-base font-normal leading-normal cursor-pointer"
                                    onClick={() => handleSorting('priceAsc')}
                                    role="button"
                                    tabIndex={0}
                                    aria-label="Ordenar por precio, menor a mayor"
                                >
                                    Precio menor a mayor
                                </span>
                            </div>
                            <div className="flex pt-4 pb-3 items-center space-x-2 self-stretch">
                                <span
                                    className="bg-transparent text-neutrals-black font-montserrat text-base font-normal leading-normal cursor-pointer"
                                    onClick={() => handleSorting('priceDesc')}
                                    role="button"
                                    tabIndex={0}
                                    aria-label="Ordenar por precio, mayor a menor"
                                >
                                    Precio mayor a menor
                                </span>
                            </div>
                            <div className="flex pt-4 pb-3 items-center space-x-2 self-stretch">
                                <span
                                    className="bg-transparent text-neutrals-black font-montserrat text-base font-normal leading-normal cursor-pointer"
                                    onClick={() => handleSorting('alphaAsc')}
                                    role="button"
                                    tabIndex={0}
                                    aria-label="Ordenar de A a Z"
                                >
                                    A-Z
                                </span>
                            </div>
                        </>
                    ) : filterType === 'price' ? (
                        <PriceSlider />
                    ) : (
                        filterOptions?.map((option, index) => (
                            <div
                                key={index}
                                className="flex  pt-4 pb-3 items-center space-x-2 self-stretch"
                            >
                                <input
                                    type="checkbox"
                                    value={option.label}
                                    checked={
                                        selectedFilters?.[filterType]?.includes(option.label) ??
                                        false
                                    }
                                    onChange={() => handleChange(option.label)}
                                    className="form-checkbox text-des-beige-500 border h-4 w-4 p-0"
                                />
                                <p className="text-neutrals-black font-montserrat text-base font-normal leading-normal mb-0">
                                    {option.label}{' '}
                                    <span className="text-my-gray font-montserrat text-base font-normal leading-normal">
                                        ({option.count})
                                    </span>
                                </p>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
}
