import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './styles.css';
import { useProducts, ProductProvider } from './ProductContext';
import { ProductContainer } from './components/ProductContainer';
import { FilterContainer } from './components/FilterContainer';
import { FilterSection } from './components/FilterSection';
import { Container } from './components/Container';

const App = () => {
    const { state } = useProducts();
    const categoriesNames = [
        ...new Set(state.allProducts.map((product) => product.category)),
    ].sort();
    const categoriesProductTypes = [
        ...new Set(state.allProducts.map((product) => product.product_type)),
    ].sort();

    return (
        <Container>
            <FilterContainer />
            <FilterSection />
            {state.filteredProducts ? (
                <ProductContainer products={state.filteredProducts} />
            ) : (
                <h3>No hay productos disponibles</h3>
            )}
        </Container>
    );
};

const RootComponent = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [initialProducts, setInitialProducts] = useState([]);

    useEffect(() => {
        let productsData = Array.isArray(window.productsData)
            ? window.productsData.sort((a, b) => {
                  const orderDifference =
                      parseInt(a.product_order, 10) - parseInt(b.product_order, 10);
                  if (orderDifference !== 0) {
                      return orderDifference;
                  }
                  return parseFloat(a.price) - parseFloat(b.price);
              })
            : [];
        productsData = productsData.filter((prod) => {
            if (
                prod['category'] === 'Griferia' &&
                (prod['product_type'] === 'Lavatorio' || prod['product_type'] === 'Cocina')
            ) {
                return true;
            } else if (
                prod['category'] === 'Griferia' ||
                prod['category'] === 'Sanitario' ||
                prod['category'] === 'Ducha'
            ) {
                return prod['product_structure'] !== 'simple';
            } else {
                return true;
            }
        });

        setInitialProducts(productsData);
        setIsLoaded(true);
    }, []);

    if (!isLoaded) {
        return (
            <div className="mainContainer">
                <div className="header-store">
                    <div className="title loading"></div>
                    <div className="filterButton loading"></div>
                </div>
                <div className="filterContainer">
                    <div className="loading"></div>
                    <div className="loading"></div>
                    <div className="loading"></div>
                    <div className="loading"></div>
                    <div className="loading"></div>
                    <div className="loading"></div>
                </div>
                <div className="productContainer">
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                    <div>
                        <div className="productImage loading"></div>
                        <div className="productCategory loading"></div>
                        <div className="productName loading"></div>
                        <div className="productPrice loading"></div>
                    </div>
                </div>
                <div className="filterContainer loading"></div>
            </div>
        );
    }
    return (
        <Router>
            <ProductProvider initialProducts={initialProducts}>
                <App />
            </ProductProvider>
        </Router>
    );
};

const root = document.getElementById('root');
if (root) {
    createRoot(root).render(<RootComponent />);
} else {
    console.error('Root element not found');
}
