import { AVAILABLE_FILTERS } from '@/constants/FILTERS';
export const getFilterOptions = (products) => {
    const result = {};
    console.log('products', products);

    AVAILABLE_FILTERS.forEach((filter) => {
        result[filter.filterType] = [];
    });

    products.forEach((product) => {
        AVAILABLE_FILTERS.forEach((filter) => {
            if (filter.filterType === 'price') {
                return false;
            }
            const value = product[filter.filterType];

            if (value) {
                const newValue = Array.isArray(value) ? value[0].name : value;
                const values = newValue.split(',').map((v) => v.trim());

                values.forEach((singleValue) => {
                    const existingOption = result[filter.filterType].find(
                        (opt) => opt.label === singleValue
                    );
                    if (existingOption) {
                        existingOption.count++;
                    } else {
                        result[filter.filterType].push({
                            label: singleValue,
                            count: 1,
                        });
                    }
                });
            }
        });
    });

    Object.keys(result).forEach((filterType) => {
        result[filterType].sort((a, b) => a.label.localeCompare(b.label));
    });

    return result;
};
