import React from 'react';

const Baldosa = (props) => {
  return (
    <svg 
      width={props.width || '32'} 
      height={props.height || '32'} 
      viewBox="0 0 32 32" 
      fill= {props.fill || '#94897F'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M28.0463 15.2178C28.2099 15.303 28.3125 15.472 28.3125 15.6562C28.3125 15.8405 28.2099 16.0095 28.0463 16.0945L23.2773 18.5748L28.0463 21.055C28.2099 21.1401 28.3125 21.3092 28.3125 21.4934C28.3125 21.6776 28.2099 21.8467 28.0463 21.9317L15.8845 28.2567C15.813 28.2939 15.7347 28.3125 15.6562 28.3125C15.5778 28.3125 15.4995 28.2938 15.428 28.2567L3.26618 21.9317C3.10259 21.8467 3 21.6776 3 21.4934C3 21.3092 3.10259 21.1401 3.26618 21.055L8.03516 18.5748L3.26618 16.0945C3.10259 16.0095 3 15.8405 3 15.6562C3 15.472 3.10259 15.303 3.26618 15.2178L8.03516 12.7377L3.26618 10.2575C3.10259 10.1724 3 10.0034 3 9.81912C3 9.6348 3.10259 9.46581 3.26618 9.38076L15.428 3.05578C15.5711 2.98141 15.7413 2.98141 15.8845 3.05578L19.992 5.19194C20.2341 5.31789 20.3284 5.61631 20.2023 5.85839C20.0763 6.10042 19.7779 6.19461 19.5356 6.06864L15.6562 4.05117L4.56547 9.81912L15.6562 15.5871L26.7471 9.81912L23.4823 8.12126C23.24 7.99529 23.1459 7.69689 23.2719 7.45481C23.3979 7.21273 23.6963 7.11859 23.9387 7.24455L28.0463 9.38082C28.2099 9.4659 28.3125 9.6349 28.3125 9.81916C28.3125 10.0034 28.2099 10.1724 28.0463 10.2575L23.2773 12.7377L28.0463 15.2178ZM22.2062 13.2947L15.8844 16.5825C15.813 16.6196 15.7345 16.6383 15.6561 16.6383C15.5778 16.6383 15.4995 16.6196 15.428 16.5825L15.1751 16.4509L10.6343 18.8124L12.607 19.8384L23.6802 14.0612L22.2062 13.2947ZM14.1041 15.8938L9.56322 18.2553L7.59991 17.2343L12.1407 14.8728L14.1041 15.8938ZM11.0696 14.3158L9.1063 13.2947L4.56543 15.6562L6.52878 16.6773L11.0696 14.3158ZM26.7471 21.4934L15.6562 27.2613L4.56547 21.4934L9.1063 19.1318L15.4281 22.4196C15.4995 22.4568 15.5779 22.4754 15.6562 22.4754C15.7347 22.4754 15.813 22.4569 15.8845 22.4196L22.2062 19.1318L26.7471 21.4934ZM13.677 20.3949L15.6562 21.4242L26.7471 15.6562L24.7503 14.6178L13.677 20.3949Z"/>
    </svg>
  );
}

export default Baldosa;
