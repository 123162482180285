// Contenedor de Titullos + FilterTags + FilterButton

import React from 'react';
import { Filter } from './Filter';
import { useProducts } from '../ProductContext';
import { ButtonAllProducts } from './ButtonAllProducts';

export function FilterContainer() {
    const { state } = useProducts();
    return (
        <div className="max-w-6xl mx-auto py-4 flex justify-between mt-6 sm:mt-6 md:mt-2 lg:hidden">
            <div className="flex flex-col sm:flex-row gap-2 w-full">
                {/* <div className="hidden md:flex">
                    <FilterTagContainer />
                </div> */}
                <Filter className="block lg:hidden flex-1" />
                <ButtonAllProducts className="block lg:hidden flex-1" />
            </div>
        </div>
    );
}
