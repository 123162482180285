import React from 'react';
import { FilterOptions } from './FilterOptions';
import { useProducts } from '../ProductContext';
import { CategoryButtons } from './CategoryButtons';
import { ButtonAllProducts } from './ButtonAllProducts';

export function FilterSection() {
    const { dispatch } = useProducts();

    const resetFiltersExceptCategory = () => {
        dispatch({
            type: 'CLEAR_FILTERS',
        });
    };

    return (
        <>
            <CategoryButtons />
            <div className="flex justify-between items-center py-4">
                <div data-component="filter-section" className={`hidden lg:block`}>
                    <FilterOptions />
                </div>
                <div>
                    <ButtonAllProducts className="hidden lg:block" />
                </div>
            </div>
        </>
    );
}
