import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProducts } from '../ProductContext';
import { FilterAccordion } from './FilterAccordion';
import { updateUrlWithFilters } from '@/utils/filters/updateUrlwithFilters';
import { AVAILABLE_FILTERS } from '@/constants/FILTERS';
export function FilterOptions() {
    const { state, dispatch } = useProducts();
    const navigate = useNavigate();
    const [openAccordionId, setOpenAccordionId] = useState(null);

    const handleAccordionClick = (id) => {
        setOpenAccordionId(openAccordionId === id ? null : id);
    };

    const currentAvailableFilters = AVAILABLE_FILTERS;

    const handleCheckboxChange = (filterType, filterOption) => {
        const newFilter = {
            [filterType]: state.filters[filterType]?.includes(filterOption)
                ? state.filters[filterType].filter((opt) => opt !== filterOption)
                : [...(state.filters[filterType] || []), filterOption],
        };

        const newFilters = { ...state.filters, ...newFilter };

        const newUrl = updateUrlWithFilters(newFilters);
        if (newUrl) {
            navigate(newUrl, { replace: true });
        }

        dispatch({ type: 'SET_FILTER', payload: newFilters });
    };

    const getOptions = (filterType) => {
        return state.filterOptions[filterType];
    };

    const handleSort = (type) => {
        let sortedProducts = [...state.filteredProducts];
        switch (type) {
            case 'priceAsc':
                sortedProducts.sort((a, b) => a.price - b.price);
                break;
            case 'priceDesc':
                sortedProducts.sort((a, b) => b.price - a.price);
                break;
            case 'alphaAsc':
                sortedProducts.sort((a, b) => a.name.localeCompare(b.name));
                break;
            default:
                break;
        }
        dispatch({ type: 'SET_SORTED_PRODUCTS', payload: sortedProducts });
    };

    return (
        <div data-component="filter-options" className="lg:flex gap-2 justify-start items-center">
            {openAccordionId && (
                <div
                    className="overlay absolute inset-0 z-10"
                    onClick={() => handleAccordionClick(null)}
                ></div>
            )}

            <div className="hidden lg:block">
                <img
                    src="https://cdn.destefano1913.com/wp-content/uploads/ic_tienda_filtros.svg"
                    alt="filter-img"
                />
            </div>

            <div className="flex flex-col gap-2"></div>
            {currentAvailableFilters.map(
                (filter) =>
                    filter.filterType !== 'category' && (
                        <FilterAccordion
                            key={filter.filterType}
                            handleSort={handleSort}
                            title={filter.title}
                            filterType={filter.filterType}
                            filterOptions={getOptions(filter.filterType)}
                            handleCheckboxChange={handleCheckboxChange}
                            selectedFilters={state.filters}
                            isOpen={openAccordionId === filter.filterType}
                            handleAccordionClick={() => handleAccordionClick(filter.filterType)}
                        />
                    )
            )}
        </div>
    );
}
