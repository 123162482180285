import React from 'react';
export const ButtonAllProducts = ({ className }) => {
    const handleClick = () => {
        window.location.href = '/tienda';
    };
    return (
        <button
            className={`w-full text-neutrals-black bg-transparent font-montserrat text-base font-medium flex  justify-center items-center gap-3 border border-custom-gray $ ${className}`}
            onClick={handleClick}
        >
            Ver toda la tienda
        </button>
    );
};
