import React from 'react';
import ReactDOM from 'react-dom';
import { FilterOptions } from './FilterOptions';
import { useProducts } from '../ProductContext';

export function FilterModal({ closeModal, isOpen }) {
    const { dispatch } = useProducts();

    const resetFiltersExceptCategory = () => {
        dispatch({
            type: 'CLEAR_FILTERS',
        });
    };

    const modalClass = isOpen ? 'translate-x-0' : 'translate-x-full';
    return ReactDOM.createPortal(
        <>
            <div
                className={`fixed inset-0 bg-black bg-opacity-50 z-40 block sm:hidden ${
                    isOpen ? 'block' : 'hidden'
                }`}
                onClick={closeModal}
            ></div>
            <div
                className={`w-full flex flex-col justify-between fixed top-0 right-0 sm:w-1/3 h-full bg-white overflow-auto transition-transform transform-gpu duration-500 ease-in-out z-[999999] ${modalClass}`}
            >
                <div>
                    <div className="flex justify-between items-center px-5 pb-5 border-b border-des-beige-300 bg-white sticky top-0 pt-5 z-30">
                        <div className="flex flex-row">
                            <h2 className="text-neutrals-black font-montserrat text-18 font-semibold leading-22">
                                Filtrar y ordenar
                            </h2>
                            <span
                                className="text-des-beige-500 font-montserrat text-[13px] font-semibold leading-[22px] underline ml-3"
                                onClick={resetFiltersExceptCategory}
                                role="button"
                                tabIndex={0}
                                style={{ cursor: 'pointer' }}
                                aria-label="Borrar todo"
                            >
                                Borrar todo
                            </span>
                        </div>
                        <span
                            onClick={closeModal}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: 'pointer' }}
                            aria-label="Close modal"
                        >
                            <img
                                className="w-7 h-7"
                                src="https://cdn.destefano1913.com/wp-content/uploads/ic_tienda_x.svg"
                                alt="cruz-icon"
                            />
                        </span>
                    </div>
                    <FilterOptions />
                </div>
                <div className="bg-[#F4EFE6] px-3 pt-5 sticky bottom-0 z-50 block sm:hidden">
                    <button
                        onClick={closeModal}
                        className="mb-5 bg-neutrals-black flex h-[58px] w-full p-[13px] py-[19px] justify-between items-center text-neutrals-white font-montserrat text-base font-medium leading-6"
                    >
                        Aplicar filtros
                        <img
                            className="w-7 h-7"
                            src="https://cdn.destefano1913.com/wp-content/uploads/ic_tienda_flecha_grande.svg"
                            alt="flecha-grande"
                        />
                    </button>
                </div>
            </div>
        </>,
        document.getElementById('root')
    );
}
