import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { filterProducts } from '@/utils/filters/filterProducts';
import { getFiltersFromUrl } from '@/utils/filters/getFiltersFromUrl';
import { getFilterOptions } from '@/utils/filters/getFilterOptions';
import { getMinMaxPrices } from './utils/filters/getMinMaxPrices';
const actionTypes = {
    SET_ALL_PRODUCTS: 'SET_ALL_PRODUCTS',
    SET_CATEGORY: 'SET_CATEGORY',
    SET_FILTER: 'SET_FILTER',
    CLEAR_FILTERS: 'CLEAR_FILTERS',
    SET_SORTED_PRODUCTS: 'SET_SORTED_PRODUCTS',
    SET_PRICE_FILTER: 'SET_PRICE_FILTER',
    SET_SHORTCODE_FILTERS: 'SET_SHORTCODE_FILTERS',
};

const ProductContext = createContext();

const initialState = {
    allProducts: [],
    currentProducts: [],
    filteredProducts: [],
    currentCategory: null,
    filters: {},
    priceRange: { min: 0, max: Infinity },
    view: window.productView,
    maxPrice: 0,
    minPrice: 0,
    shortcodeFilters: window.filtersShortcode,
    filterOptions: {},
};

const productReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PRODUCTS:
            const urlFilters = getFiltersFromUrl();
            const initialFilteredProducts = filterProducts(action.payload, urlFilters);
            let filterOptions = getFilterOptions(initialFilteredProducts);
            let { minPrice, maxPrice } = getMinMaxPrices(initialFilteredProducts);

            return {
                ...state,
                allProducts: action.payload,
                filteredProducts: initialFilteredProducts,
                currentProducts: initialFilteredProducts,
                filters: urlFilters,
                maxPrice,
                minPrice,
                filterOptions,
            };
        case actionTypes.SET_CATEGORY:
            let filteredProducts = filterProducts(state.allProducts, action.payload);
            filterOptions = getFilterOptions(filteredProducts);
            ({ minPrice, maxPrice } = getMinMaxPrices(filteredProducts));

            return {
                ...state,
                filters: action.payload,
                currentCategory: action.payload,
                filteredProducts,
                maxPrice,
                minPrice,
                currentProducts: filteredProducts,
                filterOptions: { ...filterOptions, category: state.filterOptions.category },
            };
        case actionTypes.SET_FILTER:
            const newFilters = action.payload;
            let productsToFilter = state.allProducts;

            filteredProducts = filterProducts(productsToFilter, newFilters);

            return {
                ...state,
                filters: newFilters,
                filteredProducts,
                currentProducts: filteredProducts,
            };
        case actionTypes.CLEAR_FILTERS:
            let checkFilteredProducts = state.allProducts;
            if (state.currentCategory) {
                checkFilteredProducts = state.allProducts.filter(
                    (product) => product.category === state.currentCategory
                );
            }

            return {
                ...state,
                filters: {},
                filteredProducts: checkFilteredProducts,
                currentProducts: checkFilteredProducts,
            };
        case actionTypes.SET_SORTED_PRODUCTS:
            return { ...state, filteredProducts: action.payload };
        case actionTypes.SET_PRICE_FILTER:
            const { min, max } = action.payload;

            const filteredByPrice = state.currentProducts.filter(
                (product) => product.price >= min && product.price <= max
            );

            return {
                ...state,
                priceRange: { min, max },
                filteredProducts: filteredByPrice,
                maxPrice: max,
                minPrice: min,
            };
        case actionTypes.SET_SHORTCODE_FILTERS:
            return { ...state, shortcodeFilters: action.payload };
        default:
            return state;
    }
};

export const ProductProvider = ({ children, initialProducts }) => {
    const [state, dispatch] = useReducer(productReducer, initialState);

    useEffect(() => {
        dispatch({ type: actionTypes.SET_ALL_PRODUCTS, payload: initialProducts });
    }, [initialProducts]);

    return (
        <ProductContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export const useProducts = () => {
    const context = useContext(ProductContext);
    if (context === undefined) {
        throw new Error('useProducts must be used within a ProductProvider');
    }
    return context;
};
