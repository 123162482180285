import React from 'react';

const Chevron = (props) => {
  return (
    <svg 
      width={props.width || '32'} 
      height={props.height || '32'} 
      viewBox="0 0 32 32" 
      fill= {props.fill || 'none'}
      stroke= {props.stroke || '#94897F'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 20.8824L18.9412 15.9412L14 11"  strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default Chevron;