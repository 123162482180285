import React from 'react';
import { useProducts } from '@/ProductContext';
import { useNavigate } from 'react-router-dom';
import { updateUrlWithFilters } from '@/utils/filters/updateUrlwithFilters';
import { CategoryButton } from './CategoryButton';

export const CategoryButtons = () => {
    const { state, dispatch } = useProducts();
    const navigate = useNavigate();

    const handleCheckboxChange = (filterOption) => {
        const newFilter = {
            ['category']: state.filters['category']?.includes(filterOption)
                ? state.filters['category'].filter((opt) => opt !== filterOption)
                : [...(state.filters['category'] || []), filterOption],
        };

        const newFilters = { ...newFilter };

        const newUrl = updateUrlWithFilters(newFilters);
        if (newUrl) {
            navigate(newUrl, { replace: true });
        }

        dispatch({ type: 'SET_CATEGORY', payload: newFilters });
    };

    if (!state.filterOptions.category || state.filterOptions.category.length < 2) {
        return null;
    }

    return (
        <div
            data-component="category-buttons"
            className="flex flex-row gap-2 py-4 overflow-x-scroll"
        >
            {state.filterOptions.category &&
                state.filterOptions.category.length > 1 &&
                state.filterOptions.category.map((option, index) => (
                    <div key={index}>
                        <CategoryButton
                            category={option}
                            handleCategoryClick={handleCheckboxChange}
                            isActive={state.filters.category?.includes(option.label)}
                        />
                    </div>
                ))}
        </div>
    );
};
