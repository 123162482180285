import React from 'react';

const Griferia = (props) => {
  return (
    <svg 
      width={props.width || '32'} 
      height={props.height || '32'} 
      viewBox="0 0 32 32" 
      fill={props.fill || "white"}
      stroke={props.stroke || '#94897F'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4114_1560)">
      <path d="M14.2388 24.9638C14.2388 25.8102 13.5491 26.5 12.6923 26.5H11.0465C10.1897 26.5 9.5 25.8102 9.5 24.9638V10.3398C9.5 9.49338 10.1897 8.80349 11.0465 8.80349H12.6923C13.5491 8.80349 14.2388 9.49338 14.2388 10.3398V24.9638ZM9.68694 7.04884C9.58106 7.04884 9.5 6.96386 9.5 6.86537V5.68347C9.5 5.58428 9.58177 5.5 9.68694 5.5H18.6744C18.7803 5.5 18.8614 5.58499 18.8614 5.68347V6.86537C18.8614 6.96457 18.7796 7.04884 18.6744 7.04884H9.68694ZM24.5 15.5515C24.5 15.7201 24.3627 15.8631 24.1843 15.8631H15.9839C15.8083 15.8631 15.6682 15.7229 15.6682 15.5515V14.4123C15.6682 14.2437 15.8054 14.1006 15.9839 14.1006H24.1843C24.3599 14.1006 24.5 14.2408 24.5 14.4123V15.5515Z" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4114_1560">
            <rect width="32" height="32"/>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Griferia;
