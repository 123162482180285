export const AVAILABLE_FILTERS = [
    {
        title: 'ORDENAR',
        filterType: 'sort',
    },
    {
        title: 'PRODUCTO',
        filterType: 'product_type',
    },
    {
        title: 'SERIE',
        filterType: 'serie',
    },
    {
        title: 'TONO',
        filterType: 'tono',
    },
    {
        title: 'MEDIDA',
        filterType: 'medida',
    },
    {
        title: 'PRECIO',
        filterType: 'price',
    },
    {
        title: 'CATEGORIA',
        filterType: 'category',
    },
];

export const PRODUCT_TYPE = [
    'Accesorio',
    'Accesorio ducha manual',
    'Accesorio flor ducha',
    'Accesorio inodoro',
    'Anafe a gas',
    'Asiento',
    'Bañera',
    'Bidet',
    'Cisterna',
    'Cocina',
    'Cuerpo de empotrar',
    'Ducha con transferencia',
    'Ducha manual',
    'Ducha sin transferencia',
    'Flor ducha',
    'Griferia baño',
    'Inodoro de colgar',
    'Inodoro de pie',
    'Insumos de marmolería',
    'Lavatorio',
    'Mochila',
    'Rejilla Lineal',
    'Soporte bidet',
    'Soporte inodoro',
    'Tecla',
    'Washlet',
];

export const SERIE = [
    '4 fix',
    'AP 426',
    'AQUIA IV',
    'Amarillos',
    'Acero Inoxidable',
    'Avante 822',
    'Avante 824',
    'Beige Precio Medio',
    'Blancos Italia',
    'C320',
    'C5',
    'C762',
    'Ceramic',
    'Classic',
    'Classtone',
    'Colorfeel',
    'Core',
    'DB 358',
    'Drainpoint',
    'Edition 400',
    'Fusion',
    'GR',
    'GS',
    'Globe',
    'Gris Base',
    'Jura',
    'LB',
    'LF',
    'Loop',
    'MH',
    'Modern Shower',
    'Moll',
    'Negro Premium',
    'Neorest DH',
    'Now',
    'Octa II',
    'Oli 120',
    'Otros',
    'Piedra Natural',
    'REI',
    'S114',
    'Sanitarblock',
    'Simflex',
    'Soft close',
    'Solid Surface',
    'Square',
    'Standard',
    'TS 100',
    'TS 200',
    'TX 491',
    'TX 608',
    'Tundra',
    'Válvula termoestática',
    'Warm Spa',
    'ZL',
    '900',
    'A',
    'Amarillo',
    'B',
    'Beige Precio Alto',
    'Beige Sahara',
    'Blanco Turco',
    'Blancos',
    'Blancos Grecia',
    'Breccia',
    'C',
    'Cocos',
    'D',
    'E',
    'Españoles',
    'F',
    'Granito Premium',
    'Gris Perla',
    'Gris Veteado',
    'Industrial',
    'Iron',
    'Margha Stone',
    'Mármoles Chinos',
    'Natural',
    'Negro Brasil',
    'Off',
    'Palladio',
    'Rojos Otros',
    'Rosa Del Salto',
    'San Felipe',
    'Sierra Chica',
    'Smart',
    'Timber',
    'Urban',
    'Verde Brasilero',
    'Verde Otros',
    'Verdes',
];

export const TONO = [
    'Beige',
    'Blanco',
    'Blanco con veta',
    'Cromado Pulido',
    'Gris',
    'Gris con veta',
    'Iron',
    'Negro',
    'Negro Mate',
    'Amarillo',
    'Azul',
    'Exótico',
    'Marrón',
    'Naranja',
    'Negro con veta',
    'Rojo',
    'Rosa',
    'Verde',
];

export const MEDIDA = [
    '1077X450',
    '120x120',
    '15XLL',
    '15x15',
    '15x22',
    '25 x ll',
    '260x120',
    '30.5x30.5',
    '30.5xLL',
    '40 x LL',
    '40.6xLL',
    '40x100',
    '40xll',
    '50.3x11.57',
    '50.3x85.3',
    '53.3x40.3',
    '60x120',
    '61xLL',
    '80',
    'Irregular',
];

export const CATEGORIA = ['Baldosa', 'Cocción', 'Ducha', 'Griferia', 'Sanitario', 'Complementos'];

export const CONDITIONAL_FILTERS = [
    'Corian',
    'Cuarcita',
    'Granito',
    'Mármol',
    'Neolith',
    'Purastone',
    'Terrazzo',
    'Travertino',
];
