import React, { useState } from 'react';
import { FilterModal } from './FilterModal';

export function Filter({ className }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleToggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <>
            <button
                onClick={handleToggleModal}
                className={`w-full text-neutrals-black bg-transparent font-montserrat text-base font-medium flex  justify-center items-center gap-3 border border-custom-gray ${className}`}
            >
                Filtrar
                <img
                    src="https://cdn.destefano1913.com/wp-content/uploads/ic_tienda_filtros.svg"
                    alt="filter-img"
                />
            </button>
            <FilterModal isOpen={isModalOpen} closeModal={closeModal} />
        </>
    );
}
